<!-- TODO: デカすぎるのでコンポーネントに分割する -->
<template>
  <div class="record-table">
    <div class="record-pagination-wrapper top">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="pagenated-record-list"
        class="custom-pagination"
      ></b-pagination>
    </div>

    <div id="pagenated-record-list" class="record-list">
      <div v-for="(dataRound, index) in paginatedRecordList" :key="index" class="record-round">
        <div
          v-if="index == 0 || paginatedRecordList[index].date !== paginatedRecordList[index - 1].date"
          class="date-group"
        >
          <h6 class="date-header">
            {{ unixtimeToDate(dataRound.date) }}
          </h6>
        </div>

        <div class="round-card">
          <div class="round-header">
            <div class="round-title">{{ dataRound.practiceType }} {{ dataRound.round }}回戦</div>
          </div>

          <div class="venue-section px-3 pt-3 pb-2">
            <div class="venue-info flex-grow-1">
              <div
                v-for="(contentOfReadPlaceInfo, index3) in dataRound.readPlaceInfo"
                :key="index3"
                class="venue-detail"
              >
                <div class="venue-card d-flex flex-wrap align-items-center">
                  <div v-if="dataRound.readPlaceInfo.length > 1" class="venue-number text-nowrap mr-2">
                    会場{{ index3 + 1 }}
                  </div>
                  <div v-if="contentOfReadPlaceInfo.is40cardsKaruta" class="karuta-type text-nowrap mr-3">
                    <font-awesome-icon :icon="['fas', 'cards-blank']" class="mr-1" />
                    40枚
                  </div>
                  <div class="venue-place text-nowrap mr-3">
                    <font-awesome-icon :icon="['fas', 'map-marker-alt']" class="mr-1" />
                    {{ contentOfReadPlaceInfo.place }}
                  </div>
                  <div v-if="contentOfReadPlaceInfo.readMode" class="reader-info text-nowrap">
                    <font-awesome-icon :icon="['fas', 'user']" class="mr-1" />
                    {{ contentOfReadPlaceInfo.readMode }}: {{ contentOfReadPlaceInfo.reader }}
                  </div>
                </div>
              </div>
            </div>
            <div class="venue-actions text-center pt-2">
              <b-button
                class="edit-venue-btn"
                size="sm"
                variant="outline-success"
                @click="
                  setMeibo();
                  $bvModal.show('editPlaceAndReader');
                  setParamsOfPlaceAndReaderModal(
                    -paginatedRecordList[index].date,
                    dataRound.round,
                    dataRound.readPlaceInfo
                  );
                "
              >
                <font-awesome-icon :icon="['fas', 'plus']" />
              </b-button>
            </div>
          </div>

          <div class="matches-section px-3 py-2">
            <div class="matches-summary">
              <div class="summary-content">
                <div class="match-count">
                  <strong>{{ dataRound.dataList.length }}</strong>
                  試合
                </div>
                <div class="progress-wrapper">
                  <div class="progress-label">勝敗入力率</div>
                  <b-progress :value="calculateProgress(dataRound.dataList)" max="100" class="custom-progress">
                    <b-progress-bar
                      :value="calculateProgress(dataRound.dataList)"
                      :variant="getProgressVariant(dataRound.dataList)"
                    >
                      {{ calculateProgress(dataRound.dataList) }}%
                    </b-progress-bar>
                  </b-progress>
                </div>
              </div>
            </div>

            <b-table
              striped
              bordered
              hover
              :items="dataRound.dataList"
              :fields="fields"
              class="custom-table mb-2"
              @row-clicked="setFocusRecord"
            >
              <template #[`cell(player1Name)`]="data">
                <div class="player-name">
                  <div v-if="data.item.player1Uid == ''" class="inactive-player">
                    <span style="white-space: pre-line" v-text="changeLine(data.item.player1Name)" />
                    <font-awesome-icon
                      v-if="!changeLine(data.item.player1Name).includes('(')"
                      size="sm"
                      :icon="['fas', 'user-alt-slash']"
                      class="ml-1"
                    />
                  </div>
                  <div v-else>
                    <span style="white-space: pre-line">{{ data.item.player1Name }}</span>
                  </div>
                </div>
              </template>

              <template #[`cell(player2Name)`]="data">
                <div class="player-name">
                  <div v-if="data.item.player2Uid == ''" class="inactive-player">
                    <span style="white-space: pre-line" v-text="changeLine(data.item.player2Name)" />
                    <font-awesome-icon
                      v-if="!changeLine(data.item.player2Name).includes('(')"
                      size="sm"
                      :icon="['fas', 'user-alt-slash']"
                      class="ml-1"
                    />
                  </div>
                  <div v-else>
                    <span style="white-space: pre-line">{{ data.item.player2Name }}</span>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <div class="record-pagination-wrapper bottom">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="pagenated-record-list"
        @change="scrollToTopPagination"
      ></b-pagination>
    </div>

    <b-modal :id="'edit'" hide-header hide-footer modal-center>
      <Record :record="focusRecord" :practice-type-list="practiceTypeList" modal-name="edit" :user="user" />
    </b-modal>
    <b-modal :id="'editPlaceAndReader'" hide-header hide-footer modal-center>
      <div v-for="(placeAndReader, index) in placeAndReaderList" :key="placeAndReader.index" class="mb-3">
        <h6>会場{{ index + 1 }}：</h6>
        <b-row>
          <b-col class="my-auto px-0">
            <b-form-input v-model="placeAndReader.place" placeholder="会場" list="placeOptions"></b-form-input>
            <datalist id="placeOptions">
              <option v-for="place in placeOptions" :key="place">
                {{ place }}
              </option>
            </datalist>
          </b-col>
          <b-col class="my-auto px-0">
            <b-form-checkbox v-model="placeAndReader.is40cardsKaruta" class="ml-1">40枚かるた</b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="my-auto px-0">
            <b-form-input
              v-model="placeAndReader.readMode"
              placeholder="読み形式"
              list="readModeOptions"
            ></b-form-input>
            <datalist id="readModeOptions">
              <option v-for="readMode in readModeOptions" :key="readMode">
                {{ readMode }}
              </option>
            </datalist>
          </b-col>
          <b-col class="my-auto px-0">
            <b-form-input v-model="placeAndReader.reader" placeholder="読手/操作者" list="meibo"></b-form-input>
            <datalist id="meibo">
              <option v-for="member in meibo" :key="member">
                {{ member }}
              </option>
            </datalist>
          </b-col>
        </b-row>
      </div>
      <div class="text-right">
        <b-button class="my-2 mx-2" variant="outline-success" size="sm" @click="addPlaceAndReaderRow()">
          行を追加
        </b-button>
        <b-button
          class="my-2 mx-2"
          size="sm"
          variant="success"
          @click="
            updatePlaceAndReader(minusUnixTimeOfPlaceAndReaderModal, roundOfPlaceAndReaderModal, placeAndReaderList);
            $bvModal.hide('editPlaceAndReader');
          "
        >
          更新
        </b-button>
      </div>
      <p style="font-size: 14px">
        ※ある会場の情報を削除したい場合は、その行の内容を全部空白にして更新してください。40枚かるたの☑はどちらでもOKです。
      </p>
    </b-modal>
  </div>
</template>
<script>
import Record from "../components/Record.vue";
import { getDatabase, ref, onValue, set } from "firebase/database";
import "dayjs/locale/ja";
import dayjs from "dayjs";
import OPTIONS from "../constants/options.js";

export default {
  name: "RecordTable",
  components: {
    Record,
  },
  props: {
    recordList: { type: Array, default: () => {} },
    practiceTypeList: { type: Array, default: () => {} },
    user: { type: Object, default: () => {} },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      focusRecord: {},
      fields: [
        {
          key: "player1Name",
          label: "名前",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: "width: 30%",
        },
        {
          key: "player1Result",
          label: "勝敗",
          formatter: "formatResultToShortText",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "cardNumber",
          label: "枚数",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "player2Result",
          label: "勝敗",
          formatter: "formatResultToShortText",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "player2Name",
          label: "名前",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: "width: 30%",
        },
      ],
      minusUnixTimeOfPlaceAndReaderModal: new Number(),
      roundOfPlaceAndReaderModal: new Number(),
      placeAndReaderList: [],
      readModeOptions: OPTIONS.READ_MODE_OPTIONS,
      placeOptions: OPTIONS.PLACE_OPTIONS,
    };
  },
  computed: {
    totalRows() {
      return this.recordList.length;
    },
    paginatedRecordList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.recordList.slice(start, end);
    },
  },
  methods: {
    changeLine(playerName) {
      return playerName.replace(/\(|（/, "\n(").replace(/\)|）/, ")");
    },
    setFocusRecord(record) {
      this.setMeibo();
      this.focusRecord = record;
      this.$bvModal.show("edit");
    },
    setMeibo() {
      let meiboHash = new Object();
      const database = getDatabase();
      const meiboRef = ref(database, "meibo");
      onValue(meiboRef, (snapShot) => {
        if (snapShot) {
          snapShot.forEach((data) => {
            let meiboMemberByYear = data.val();
            for (let member in meiboMemberByYear) {
              meiboMemberByYear[member]["year"] = data.key;
            }
            meiboHash = Object.assign(meiboHash, meiboMemberByYear);
          });
        }
      });
      this.meibo = Object.keys(this.sortMeiboHash(meiboHash));
    },
    sortMeiboHash(meiboHash) {
      let meiboArray = new Array();
      for (let person in meiboHash) {
        let kana = meiboHash[person].kana;
        let personObj = {
          kana: kana,
          name: person,
          value: meiboHash[person],
        };
        meiboArray.push(personObj);
      }
      meiboArray.sort(function (a, b) {
        if (a.kana < b.kana) {
          return -1;
        } else {
          return 1;
        }
      });
      let meiboObj = new Object();
      for (let i in meiboArray) {
        meiboObj[meiboArray[i].name] = meiboArray[i].value;
      }
      return meiboObj;
    },
    formatResultToShortText(num) {
      switch (num) {
        case 1:
          return "○";
        case 0:
          return "×";
        case -1:
          return "指導";
        case -2:
          return "1人";
        case -3:
          return "T.U.";
        case "":
          return "";
      }
    },
    setParamsOfPlaceAndReaderModal(minusUnixTime, round, readPlaceInfo) {
      this.minusUnixTimeOfPlaceAndReaderModal = minusUnixTime;
      this.roundOfPlaceAndReaderModal = round;
      this.placeAndReaderList = [];
      for (let index in readPlaceInfo) {
        //firebase上で""(空白文字)のデータがこれによって"記載なし"に書き換わる・・・
        this.placeAndReaderList.push({
          place: readPlaceInfo[index].place,
          readMode: readPlaceInfo[index].readMode,
          reader: readPlaceInfo[index].reader,
          is40cardsKaruta: readPlaceInfo[index].is40cardsKaruta,
        });
      }
    },
    addPlaceAndReaderRow() {
      // 会場と読手の行を追加
      const result = {
        place: "",
        readMode: "",
        reader: "",
      };
      this.placeAndReaderList.push(result);
    },
    updatePlaceAndReader(minusUnixTimeOfPlaceAndReaderModal, roundOfPlaceAndReaderModal, placeAndReaderList) {
      let placeAndReaderObj = new Object();
      for (let i in placeAndReaderList) {
        let placeAndReaderInfo = placeAndReaderList[i];
        if (placeAndReaderInfo.place == "" && placeAndReaderInfo.readMode == "" && placeAndReaderInfo.reader == "")
          continue;
        placeAndReaderObj[i] = placeAndReaderInfo;
      }
      const database = getDatabase();
      const placeAndReaderRef = ref(
        database,
        "records/" + minusUnixTimeOfPlaceAndReaderModal + "/" + roundOfPlaceAndReaderModal + "/placeInfo"
      );
      set(placeAndReaderRef, placeAndReaderObj);
    },
    scrollToTopPagination() {
      const topPagination = document.querySelector(".record-pagination-wrapper.top");
      if (topPagination) {
        topPagination.scrollIntoView({ behavior: "smooth" });
      }
    },
    show40cardsKaruta(is40cardsKaruta) {
      if (is40cardsKaruta == true) return "(40枚かるた)";
      if (is40cardsKaruta == false) return;
    },
    unixtimeToDate(unixtime) {
      return dayjs(unixtime * 1000).format("YYYY/MM/DD (dd)");
    },
    calculateProgress(dataList) {
      const total = dataList.length;
      const completed = dataList.filter((item) => item.player1Result !== "" && item.player2Result !== "").length;
      return Math.round((completed / total) * 100);
    },
    getProgressVariant(dataList) {
      const progress = this.calculateProgress(dataList);
      if (progress < 50) {
        return "danger"; // 赤
      } else if (progress < 75) {
        return "warning"; // 黄色
      } else if (progress < 100) {
        return "info"; // 青
      } else {
        return "success"; // 緑
      }
    },
  },
};
</script>
<style scoped>
.record-table {
  padding: 1.5rem;
}

.date-group {
  margin: 2rem 0 1rem;
}

.date-header {
  color: #2c3e50;
  font-weight: 600;
  font-size: 1.2rem;
  padding-left: 0.5rem;
  border-left: 4px solid #3498db;
}

.round-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  overflow: hidden;
}

.round-header {
  background: #f8f9fa;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.round-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2c3e50;
}

.venue-section,
.matches-section {
  border-bottom: 1px solid #dee2e6;
}

.venue-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

.venue-card {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid #dee2e6;
}

.venue-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.venue-number {
  font-weight: 600;
  color: #2c3e50;
}

.karuta-type {
  color: #666;
  font-size: 0.9rem;
  background: #e9ecef;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}

.venue-body > div {
  margin-bottom: 0.5rem;
}

.venue-place {
  font-weight: 500;
}

.reader-info {
  color: #666;
}

.venue-actions {
  text-align: right;
}

.matches-summary {
  padding: 1rem 0;
}

.summary-content {
  display: flex;
  align-items: center;
  gap: 2rem;
  max-width: 600px;
}

.match-count {
  white-space: nowrap;
  font-size: 1.1rem;
  color: #2c3e50;
  min-width: 80px;
}

.progress-wrapper {
  flex: 1;
  min-width: 200px;
}

.progress-label {
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
  color: #666;
}

.custom-progress {
  height: 20px !important;
  border-radius: 10px;
}

.custom-table {
  margin-top: 1rem;
  border-radius: 8px;
  overflow: hidden;
}

.custom-table ::v-deep th {
  background: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
}

@media (max-width: 768px) {
  .record-table {
    padding: 0rem;
  }

  .venue-info {
    grid-template-columns: 1fr;
  }

  .summary-content {
    gap: 1rem;
  }

  .progress-wrapper {
    min-width: 150px;
  }
}
</style>
