<template>
  <div class="container-fluid login-container">
    <div class="d-flex justify-content-center flex-column align-items-center" style="height: 100dvh">
      <b-card no-body class="login-card">
        <b-card-body>
          <div class="logo-wrapper mb-4">
            <b-img :src="require('../assets/logo.png')" fluid center rounded="circle" width="100%" />
          </div>
          <b-card-title class="text-center app-title">空蝉</b-card-title>
          <b-card-sub-title class="text-center mb-4">東京大学かるた会 練習記録アプリ</b-card-sub-title>

          <b-button
            variant="light"
            class="w-100 mb-4 d-flex align-items-center justify-content-center google-btn"
            :disabled="isLoading"
            @click="signInWithGoogle"
          >
            <b-spinner v-if="isLoading" small class="mr-2"></b-spinner>
            <img
              v-else
              src="https://www.google.com/favicon.ico"
              alt="Google"
              class="me-2"
              style="width: 18px; height: 18px"
            />
            {{ isLoading ? "ログイン中..." : "Googleでログイン" }}
          </b-button>

          <div class="line-notice mb-4">
            <font-awesome-icon icon="info-circle" class="me-2" />
            LINEからこの画面を開いた場合は、SafariやGoogle Chromeで開き直してください。
          </div>

          <b-btn v-b-toggle.accordion1 class="info-btn" block>
            <span class="when-open">
              <font-awesome-icon icon="chevron-down" class="me-2" />
            </span>
            <span class="when-closed">
              <font-awesome-icon icon="chevron-right" class="me-2" />
            </span>
            はじめてログインする方へ
          </b-btn>

          <b-collapse id="accordion1" class="info-collapse mt-3" role="tabpanel">
            <div class="info-content">
              大学のメールアドレス（~@~.ac.jpおよび~@~.ed.jpなど）でのログインは行えないようにしています。
              <br />
              私用のメールアドレスでのログインをお願いします。
              <br />
              「Googleでログイン」をタップしたあとに、私用のGoogleアカウントを選んでください。
            </div>
          </b-collapse>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import firebaseConfig from "@/config/firebaseConfig";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

export default {
  name: "LoginPage",
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    // Firebase v9の初期化
    const app = initializeApp(firebaseConfig);
    getAuth(app);
  },
  methods: {
    async signInWithGoogle() {
      if (this.isLoading) return;

      this.isLoading = true;
      try {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
        this.$router.push("/records");
      } catch (error) {
        console.error("Google認証エラー:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.login-container {
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.login-card {
  max-width: 400px;
  width: 90%;
  border: none;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.logo-wrapper {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  padding: 8px;
  background: white;
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.app-title {
  font-size: 2rem;
  font-weight: 700;
  color: #2c3e50;
}

.google-btn {
  border: 1px solid #dadce0;
  border-radius: 8px;
  color: #3c4043;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.25px;
  transition: all 0.2s ease-in-out;
  height: 48px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

.google-btn:hover {
  background-color: #4285f4;
  border-color: #4285f4;
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(66, 133, 244, 0.3);
  transform: translateY(-1px);
}

.google-btn:active {
  background-color: #3367d6;
  border-color: #3367d6;
  transform: translateY(0);
}

.line-notice {
  font-size: 0.9rem;
  color: #666;
  text-align: center;
  padding: 12px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.info-btn {
  background-color: transparent;
  border: 2px solid #4285f4;
  color: #4285f4;
  border-radius: 8px;
  font-weight: 500;
  padding: 10px;
}

.info-btn:hover {
  background-color: #4285f4;
  color: white;
}

.info-collapse {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
}

.info-content {
  font-size: 0.9rem;
  line-height: 1.6;
  color: #666;
}

.collapsed > .when-open {
  display: none;
}
.not-collapsed > .when-closed {
  display: none;
}

.google-btn:disabled {
  background-color: #f1f3f4;
  border-color: #dadce0;
  color: #80868b;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}
</style>